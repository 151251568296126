@font-face {
  font-family: Signika;
  src: url("../assets/fonts/signika/Signika-Regular.otf");
  font-display: swap;
}
@font-face {
  font-family: Signika;
  src: url("../assets/fonts/signika/Signika-Semibold.otf");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: Signika;
  src: url("../assets/fonts/signika/Signika-Light.otf");
  font-display: swap;
  font-weight: 100;
}
@font-face {
  font-family: Signika;
  src: url("../assets/fonts/signika/Signika-Bold.otf");
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../assets/fonts/gotham-rounded/gotham-rounded-medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../assets/fonts/gotham-rounded/gotham-rounded-bold.otf");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../assets/fonts/gotham-rounded/gotham-rounded-light.otf");
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/source-sans-pro/SourceSansPro-Regular.otf");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/source-sans-pro/SourceSansPro-Semibold.otf");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/source-sans-pro/SourceSansPro-Light.otf");
  font-display: swap;
  font-weight: 100;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/source-sans-pro/SourceSansPro-Bold.otf");
  font-display: swap;
  font-weight: 900;
}
